@import "src/styles/variables";

.control_panel_container {
    padding: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 2px solid #EBEBEB;

    .panel_left > *{
        margin: 0 8px;

        &:first-child {
            margin-inline-start: 0;
        }
    }

    .panel_left, .panel_right{
        display: flex;
        flex-direction: row;
    }

}