@import "/src/styles/variables.scss";

.root {
    min-width: 55vw;

    .search_container {
        display: flex;
    }

    .bind_playlist_items_container {
        margin-top: 10px;
        overflow-y: auto;
        height: calc(80vh - 135px);
    
        .bind_item_container {
            cursor: pointer;
            padding: 5px 0;
            margin-bottom: 5px;
            border-bottom: 1px solid $modest-color;
            display: flex;
            align-items: center;
    
            .preview_container {
                margin-inline-end: 10px;
    
                img {
                    width: 45px;
                    height: 45px;
                    border-radius: $border-radius;
                }
            }
    
            .name_container {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    
        .show_more {
            margin-top: 20px;
            display: flex;
            justify-content: center;
    
            span {
                display: inline-block;
                cursor: pointer;
                padding: 8px 16px;
                border-radius: $border-radius;
                border: 1px solid $modest-color;
                color: $font-color-modest;
    
                &:hover {
                    color: black;
                    border-color: $primary-color;
                }
            }
        }
    }

    .footer_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .buttons_container {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 5px;
            height: 50px;
    
            >* {
                margin: 0 5px;
            }
        }
    }
}