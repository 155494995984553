$base-color: #FFFFFF;
$primary-color: #0061AE;
$modest-color: #F2F2F2;
$danger-color: #DD473B;
$secondary-color: #ADADAD;
$success-color: #198754;

$font-color-modest: #ADADAD;

$border-radius: 4px;
$border-modest-color: #EBEBEB;

$navbar-height: 50px;
$margin-large: 24px;
