@import "src/styles/variables";

.playlistItem {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 10px 0;
    padding-left: 10px;
    margin: 5px 8px;
    border-radius: $border-radius;

    &:hover,
    &.active {
        background-color: rgba($modest-color, 0.7);

        .deleteIcon {
            display: inline-block;
        }
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 100%;
        width: 4px;
        background-color: $primary-color;
        transition: all 0.3s ease-out;
        z-index: 10;
        border-radius: 0 $border-radius $border-radius 0;

        [dir="rtl"] & {
            right: auto;
            left: 0;
            border-radius: $border-radius 0 0 $border-radius;
        }
    }

    &.active::after,
    &:hover::after {
        bottom: 0;
    }

    .previewContainer {
        position: relative;
        border-radius: $border-radius;
        height: 60px;
        display: flex;
        justify-content: center;

        background-color: rgba($color: #000000, $alpha: 0.1);

        img {
            max-width: 100%;
            height: 60px;
        }

        span {
            display: inline-block;
            position: absolute;
            bottom: 4px;
            right: 4px;
            color: white;
            padding: 2px;
            border-radius: $border-radius;
            height: 19px;
            width: 19px;
        }
    }

    .playlistItemName {
        margin-inline-start: 10px;

        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        text-overflow: ellipsis;
    }

    .deleteIcon {
        position: absolute;
        display: none;
        top: 8px;
        right: 8px;

        [dir="rtl"] & {
            right: auto;
            left: 8px;
        }
    }
}