@import "src/styles/variables";

.selectTriggerType-container {
    min-width: 30vw;
    min-height: 20vh;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media all and (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);

        svg {
            width: 80px;
            height: 80px;
        }
    }

    .selectTriggerType-item {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 25px;
        border-radius: $border-radius * 2;
        border: 2px rgba($modest-color, 1) solid;

        &:hover {
            border-color: $primary-color
        }

        &.disabled {
            border: none;
            cursor: default;
        }

        .disabled {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba($modest-color, 0.6);
            border-radius: $border-radius * 2;
        }

        div {
            span {
                display: block;
            }

            .trigger-name {
                font-size: 16px;
                margin-top: 15px;
            }

            .trigger-description {
                font-size: 14px;
                margin-top: 8px;
                color: $font-color-modest;
            }
        }
    }
}