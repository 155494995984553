@import "src/styles/variables";

.player-container {
    display: flex;
    justify-content: center;
    align-items: center;

    video,
    img {
        max-width: 100%;
        max-height: 80vh;
        border-radius: $border-radius;
    }
}