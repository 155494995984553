@import "/src/styles/variables.scss";

.settings_node_container {
    padding: 8px;

    .name {
        padding-bottom: 8px;
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        text-overflow: ellipsis;
    }

    .settings_container {
        >* {
            padding: 8px 0;

            label {
                display: inline-block;
                color: $font-color-modest;
                margin-bottom: 4px;
            }

            .input_container {
                position: relative;

                .seconds {
                    position: absolute;
                    font-size: 14px;
                    right: 30px;
                    bottom: calc(50% - 7px);
                    color: $font-color-modest;
    
                    [dir="rtl"] & {
                        right: auto;
                        left: 30px;
                    }
                }
            }
        }
    }
}