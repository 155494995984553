@import "/src/styles/variables.scss";

.add_content_item_container {
    cursor: grab;
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 1px solid $modest-color;

    &:hover {
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06),
            0 2px 6px rgba(0, 0, 0, 0.04),
            0 0 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
    }

    span {
        display: inline-block;
        margin: 0 10px;
        font-size: 15px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        text-overflow: ellipsis;
    }

    .preview_container {
        display: flex;
        justify-content: center;
        background-color: rgba($color: #000000, $alpha: 0.1);

        img {
            max-width: 40px;
            height: 40px;
            border-radius: $border-radius;
        }
    }
}