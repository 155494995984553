@import "src/styles/variables";

.createScenarioContainer>.rightControlPanel {
    .controlsContainer {
        height: 100%;

        .switchControlPanel {
            display: flex;
            margin-bottom: 10px;
            background-color: $modest-color;
            padding-top: 2px;

            >* {
                display: flex;
                align-items: center;
                width: 50%;
                padding: 20px;
                margin: 0 2px;
                cursor: pointer;
                color: $font-color-modest;

                span {
                    display: inline-block;
                    margin-inline-end: 5px;

                    @media all and (max-width: 1300px) {
                        font-size: 13px;
                    }
                }

                @media all and (max-width: 1300px) {
                    padding: 16px 8px;
                }

                svg {
                    transition: all 0.3s ease-in-out;
                    transform: rotate(-90deg);

                    @media all and (max-width: 1300px) {
                        width: 18px;
                        height: 18px;
                    }
                }

                &.active {
                    color: $primary-color;
                    background-color: white;
                    border-radius: 16px 16px 0 0;

                    svg {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .settings__addContent {
            height: calc(100% - 70px);

            >* {
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}