@import "/src/styles/variables.scss";

.triggers-container {

    .add-triggers-container {
        padding: 8px;

        .title {
            display: inline-block;
            font-size: 16px;
            color: $font-color-modest;
            margin-bottom: 10px;
        }

        .add-trigger-but {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border: 2px $secondary-color dashed;
            border-radius: $border-radius;
            cursor: pointer;
            max-height: 50vh;
            overflow-y: auto;

            span {
                display: inline-block;
                color: black;
                margin-left: 8px;
            }

            &:hover {
                border-color: $primary-color;
                color: $primary-color;

                span {
                    color: $primary-color;
                }
            }
        }
    }

    .triggers-items {
        max-height: 65vh;
        overflow-y: auto;

        details {
            padding: 8px 14px;
            border-radius: $border-radius;

            &:hover {
                background-color: rgba($modest-color, 0.6);
            }

            summary {
                font-size: 14px;
                cursor: pointer;
                color: $font-color-modest;
            }

            div {
                .trigger-item {                    
                    .short-name-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding: 6px 0;
                        cursor: pointer;
                        font-size: 14px;

                        .delete-icon {
                            transition: all 0.3s ease;
                            opacity: 0.3;
                        }
                    
                        &:hover {
                            color: $primary-color;

                            .delete-icon {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}