.loadForm {
    min-width: 55vw;

    .loadInputFile {
        width: 100%;
        height: 200px;

        @media all and (max-height: 700px) {
            height: 150px;
        }

        @media all and (max-height: 500px) {
            height: 100px;
        }
    }

    .loadedItems {
        margin-top: 16px;
        height: 250px;
        max-height: 250px;
        border-bottom: 1px solid #EBEBEB;
        overflow-y: auto;

        @media all and (max-height: 700px) {
            height: 150px;
        }

        @media all and (max-height: 500px) {
            height: 150px;
        }
    }

    .loadSuccsess {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .loadInfo {
            color: #ADADAD;
            font-size: 18px;
        }

        .loadSuccsessButtons {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 5px;

            >* {
                margin: 0 10px;
            }
        }
    }
}