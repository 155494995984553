@import "/src/styles/variables.scss";

.root {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    height: calc(100vh - $navbar-height);
    width: 65%;
    margin: auto;

    .form_container {
        padding: 26px;
        background-color: white;
        border-radius: $border-radius;

        .form_body {
            >* {
                margin: 26px 0;
            }
        }
    }

    .hello_text {
        padding: 50px;

        h1 {
            font-weight: 700;
            font-size: 48px;
            margin-bottom: 30px;
        }

        p {
            font-weight: 400;
            font-size: 20px;
        }

        @media all and (max-width: 1300px) {
            h1 {
                font-size: 38px;
            }
    
            p {
                font-size: 16px;
            }
        }
    }
}