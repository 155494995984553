@import "/src/styles/variables";

.modal_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 55;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 50px;

    &.position_up {
        align-items: flex-start;
    }

    .modal_overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 55;

        width: 100%;
        height: 100%;

        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .modal_content {
        position: relative;
        z-index: 60;

        max-height: 90vh;
        max-width: 70vw;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 16px;
        border-radius: $border-radius;

        background: white;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);

        .modal_control {
            position: relative;
            width: 100%;
            margin-bottom: 24px;

            .modal_title {
                .title {
                    font-weight: 500;
                    font-size: 20px;
                }

                .subtitle {
                    margin-top: 6px;
                    font-size: 14px;
                    color: $font-color-modest;
                }
            }

            .close {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 15;

                cursor: pointer;
            }
        }

        .modal_body {
            flex: 1 1 auto;
        }

        .buttons_container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            padding-top: 8px;
            border-top: 2px solid #F4F4F4;

            button {
                height: auto;
                padding: 7px 10px;
            }

            >*:first-child {
                margin-inline-end: 4px;
            }

            >*:last-child {
                margin-inline-start: 4px;
            }
        }
    }
}