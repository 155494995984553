@import "/src/styles/variables.scss";

.loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.root {
    background: transparent;
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 24px;

    >div {
        background: white;
        border-radius: $border-radius;
    }

    .control_panel {
        h2 {
            padding: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .terminal_name {
                font-size: 20px;
                font-weight: 600;
                margin-inline-end: 8px;
            }

            .connection_state {
                border-radius: 16px;
                background-color: rgba($danger-color, 0.8);
                color: white;
                padding: 4px 8px;
                font-size: 11px;
                font-weight: 500;

                &.active {
                    background-color: rgba($success-color, 0.8);
                }
            }
        }
    }

    .setup_container {
        padding: 16px 24px;

        h2 {
            font-size: 16px;
            color: $font-color-modest;
            margin-bottom: 40px;
        }

        h3 {
            font-size: 12px;
            margin-bottom: 18px;
            color: $font-color-modest;
        }

        >* {
            margin-bottom: 16px;
        }
    }
}