@import "/src/styles/variables";

.select_container {
    position: relative;

    .selected_container {
        width: 100%;
        height: 45px;

        display: flex;
        align-items: center;

        overflow-x: hidden;

        padding: 10px;
        padding-inline-end: 40px;
        border: 1px solid $modest-color;
        border-radius: $border-radius;
        background: white;
        box-shadow: 0 1px 3px -2px #9098A9;
        cursor: pointer;
        font-family: inherit;
        font-size: 16px;
        transition: all 150ms ease;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:active,
        &:hover,
        &:focus {
            outline: none;
            border-color: $primary-color;
        }
    }

    .option_list {
        max-height: 300px;
        overflow: auto;
        position: absolute;
        z-index: 999;
        width: 100%;
        background-color: #FAFAFA;
        border-radius: $border-radius;

        .option {
            cursor: pointer;
            padding: 5px 10px;

            &.active,
            &:hover {
                background-color: $modest-color;
            }
        }
    }

    .select_icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        top: 10px;
        right: 8px;
        background-size: cover;

        transition: all 0.3s ease-in-out;
        transform: rotate(90deg);

        [dir="rtl"] & {
            right: auto;
            left: 8px;
            transform: rotate(-90deg);
        }
    }

    &.up {
        .option_list {
            bottom: 100%;
            margin-bottom: 5px;
        }

        &.open {
            .select_icon {
                transform: rotate(180deg);

                [dir="rtl"] & {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &.down {
        .option_list {
            top: 100%;
            margin-top: 5px;
        }

        &.open {
            .select_icon {
                transform: rotate(0deg);
            }
        }
    }

    &.open {
        .option_list {
            border: 1px solid $modest-color;
        }
    }
}