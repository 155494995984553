@import "/src/styles/variables";

.drop_zone {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px $modest-color dashed;
    border-radius: $border-radius;

    &.drop_zone_over {
        cursor: grabbing;
        border-color:rgba($primary-color, 0.6);

        .drag_and_drop_container {
            background-color: rgba($primary-color, 0.3);
        }

        .label_container {
            z-index: 0;
        }
    }

    label {
        cursor: pointer;
    }

    .icon_container {
        @media all and (max-height: 500px) {
            display: none;
        }
    }

    .drag_and_drop_container {
        position: absolute;
        z-index: 5;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .label_container {
        position: relative;
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $font-color-modest;

        >* {
            font-size: 14px;
            padding: 5px;
        }

        .upload_label {
            padding: 5px;
            color: $primary-color;
            border-radius: $border-radius;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    input {
        display: none;
    }
}