@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    font-size: 14px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: $border-radius;
        background-color: rgba($border-modest-color, 1);
    }
}

body {
    max-width: 1920px;
    min-width: 1000px;
    background: $modest-color;
    margin: auto;
}

.content-table {
    overflow-y: auto;
    flex: 1 1 auto;

    .table-item {
        position: relative;
        display: grid;
        align-items: center;
        padding: 18px 26px;
        border-bottom: 2px solid #F4F4F4;

        .checkbox-container {
            padding-inline-end: 18px;
        }

        .preview-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .name {
                margin-inline-start: 10px;
            }

            .preview {
                display: flex;
                justify-content: center;
                width: 40px;
                background-color: rgba($color: #000000, $alpha: 0.1);
                border-radius: $border-radius;

                img {
                    max-width: 40px;
                    height: 40px;
                    border-radius: $border-radius;
                }
            }
        }

        .description-container {
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
        }

        .settings-container {
            display: grid;
            grid-template-columns: inherit;
            gap: 10px;

            .action-container {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                &:nth-child(2) {
                    margin: auto;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0px;
            left: 0;
            width: 2px;
            height: 0;
            background-color: $primary-color;
            transition: all 0.3s ease-out;
            z-index: 10;

            [dir="rtl"] & {
                left: auto;
                right: 0;
            }
        }

        &.active::after,
        &:hover::after {
            height: 100%;
        }

        &.cols-3 {
            grid-template-columns: 0fr 3fr 5fr 100px;
        }

        &.cols-4 {
            grid-template-columns: 0fr 4fr 3fr 3fr 100px;
        }
    }
}