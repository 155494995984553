@import "src/styles/variables";

.settings_container{
    ul{
        padding: 15px 8px;
        border-bottom: 1px solid $modest-color;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }

        li{
            padding: 4px 12px;
            margin: 4px 10px 4px 0;
            font-size: 16px;
            cursor: pointer;

            &:first-child{
                cursor: auto;
                color: $font-color-modest;
                padding-inline-start: 8px;
            }

            &.active{
                color: $primary-color;
                background-color: rgba($modest-color, 0.6);
                border-radius: $border-radius;
            }
        }
    }
}