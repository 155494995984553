@import "/src/styles/variables.scss";

.content_list_container {
    padding: 12px;
    padding-top: 0;
    margin-top: 12px;
    max-height: 70vh;
    overflow: auto;

    >div {
        margin: 8px 0;
    }

    h2 {
        font-size: 16px;
        cursor: pointer;
        color: $font-color-modest;
        display: flex;
        align-items: center;

        .icon_plus {
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-inline-start: 4px;

            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: calc(50% - 1px);
                width: 2px;
                background-color: $font-color-modest;

                transition: all 0.3s ease-in-out;
            }

            &::after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: calc(50% - 1px);
                height: 2px;
                background-color: $font-color-modest;
            }

            &.active {
                &::before {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .item_container {
        position: relative;
        padding: 4px 8px;
        margin: 2px 0;
        border-radius: $border-radius;

        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 100%;
            width: 4px;
            background-color: $primary-color;
            transition: all 0.3s ease-out;
            z-index: 10;
            border-radius: 0 $border-radius $border-radius 0;

            [dir="rtl"] & {
                right: auto;
                left: 0;
                border-radius: $border-radius 0 0 $border-radius;
            }
        }

        &.active::after,
        &:hover::after {
            bottom: 0;
        }

        &.active,
        &:hover {
            background-color: rgba($modest-color, 0.6);
        }

        span {
            display: block;
        }

        .node_name {
            font-size: 14px;
        }

        div {
            padding-inline-start: 4px;

            span {
                font-size: 13px;
                color: $font-color-modest;
            }
        }

        .conditions {
            margin-top: 4px;
            
            h3 {
                font-size: 13px;
                color: $font-color-modest;
            }

            span {
                display: inline-block;
            }
        }
    }
}