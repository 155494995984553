.sort_panel_container {
    display: grid;
    align-items: center;
    padding: 18px 26px;
    border-bottom: 2px solid #F4F4F4;

    &.cols_4 {
        grid-template-columns: 0fr 3fr 5fr 100px;
    }

    &.cols_5 {
        grid-template-columns: 0fr 4fr 3fr 3fr 100px;
    }
}