.tooltip_container {
    position: relative;
    cursor: pointer;
}

.tooltip_text {
    position: absolute;
    display: block;
    width: fit-content;
    white-space: nowrap;
    background-color: rgba($color: black, $alpha: 0.7);
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    z-index: 100;
}