@import "src/styles/variables";

.scenarioSettings-container {
    min-width: 40vw;
    
    .item {
        span {
            display: inline-block;
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: 16px;
                
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .buttons-container {
        margin-top: 25px;
        padding-top: 10px;
        border-top: 1px $modest-color solid;
        display: flex;
        justify-content: flex-end;

        >* {
            margin: 10px;
        }
    }
}