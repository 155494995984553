@import "src/styles/variables";

.pagination_container {
    display: flex;
    justify-content: flex-end;
    padding: 15px;

    >* {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0 10px;

        .arrow {
            cursor: pointer;
        }

        .numbers_container {
            span {
                display: inline-block;
                padding: 10px;
                font-size: 16px;
                margin: 0 4px;

                &.number {
                    background: $modest-color;
                    border-radius: $border-radius;
                    cursor: pointer;
                }

                &.current {
                    background: $primary-color;
                    color: white;
                }
            }
        }
    }

    select {
        background: $modest-color;
        border: none;
    }
}