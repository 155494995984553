@import "src/styles/variables";

.createPlaylistContainer {
    position: relative;
    display: flex;
    height: calc(100vh - $navbar-height);

    .controlPanel {
        position: relative;
        width: 20%;
        background-color: $base-color;
        border: 1px solid $modest-color;
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06),
            0 2px 6px rgba(0, 0, 0, 0.04),
            0 0 1px rgba(0, 0, 0, 0.04);
    }

    .slider-navigation {
        .swiper-slide {
            opacity: 0.35;
        }
    
        .swiper-slide-thumb-active {
            opacity: 1;
        }
    }
}