@import "src/styles/variables";

.createPlaylistContainer>.rightControlPanel {
    padding: 0 8px;

    h1 {
        text-align: center;
        font-size: 20px;
        margin-top: 30px;
    }

    .settingsPlaylistItem {
        .playback_duration, .blackout_duration {
            padding: 8px;
            
            label {
                display: inline-block;
                color: $font-color-modest;
                font-size: 16px;
                margin-bottom: 8px;
            }

            >div {
                position: relative;

                .seconds {
                    position: absolute;
                    font-size: 14px;
                    right: 30px;
                    bottom: calc(50% - 7px);
                    color: $font-color-modest;
    
                    [dir="rtl"] & {
                        right: auto;
                        left: 30px;
                    }
                }
            }
        }

        .select-item-type {
            padding: 8px;

            .title {
                display: inline-block;
                color: $font-color-modest;
                font-size: 16px;
                margin-bottom: 8px;
            }
        }
    }
}