@import "/src/styles/variables.scss";

.view_content_container {
    position: relative;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
        position: relative;
        max-width: 80%;
        max-height: 80%;
        border-radius: $border-radius;
        box-shadow: 16px 16px 24px rgba(0, 0, 0, 0.3),
            0 2px 6px rgba(0, 0, 0, 0.3),
            -16px 16px 24px rgba(0, 0, 0, 0.3);
    }

    .scenario_content_container {
        overflow: hidden;
        position: relative;
        height: 100%;

        .swiper {
            height: 80%;

            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
        }

        .swiper_2 {
            height: 20%;
            padding: 10px 60px;

            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
        }
    }
}