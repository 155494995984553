@import "/src/styles/variables.scss";

.root {
    position: relative;
    background-color: white;
    border-radius: $border-radius;
    padding: 18px;
    color: $font-color-modest;

    &:first-child {
        margin-inline-start: 0;
    }

    &:last-child {
        margin-inline-end: 0;
    }
}

.name {
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        margin-inline-start: 4px;

        &::first-letter {
            text-transform: capitalize;
        }
    }
}

.question {
    position: absolute;
    top: 8px;
    right: 8px;

    [dir="rtl"] & {
        right: auto;
        left: 8px;
    }
}

.body {
    font-size: 2rem;
    margin-top: 14px;
    margin-bottom: 8px;
    color: black;
    font-weight: 300;
}

.footer {
    display: flex;
    justify-content: space-between;

    .increase {
        color: rgba($success-color, 0.8);
    }

    .decrease {
        color: rgba($danger-color, 0.8);
    }
}