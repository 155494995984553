.enter {
    opacity: 0;
    transform: scale(0.01);
}

.enterActive {
    transition: opacity 100ms ease-out 200ms, transform 100ms ease-out 200ms;
    opacity: 1;
    transform: scale(1);
}

.exit {
    opacity: 1;
    transform: scale(1);
}

.exitActive {
    opacity: 0;
    transform: scale(0.01);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}