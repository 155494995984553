@import "/src/styles/variables.scss";

.root {
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        font-weight: 600;
        font-size: 24px;
    }

    .main_container {
        flex: 1 1 auto;

        >* {
            margin-bottom: 16px;
        }

        .resolutions_container {
            display: flex;
            align-items: center;
    
            >* {
                margin-inline-end: 8px;
            }
        }
    }

    .button_container {
        display: flex;
    }
}