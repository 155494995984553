@import "src/styles/variables";

.createPlaylistContainer>.leftControlPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .controlPanelTop {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .switchControlPanel {
            display: flex;

            >* {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                padding: 15px;
                cursor: pointer;
                background-color: $modest-color;
                color: $font-color-modest;
                border: 1px solid $modest-color;
                border-radius: 0 0 10px 10px;

                &.active {
                    color: $primary-color;
                    background-color: white;
                }
            }
        }

        .name-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid $modest-color;

            .name {
                font-size: 16px;
                overflow: hidden;
                white-space: nowrap;
                word-wrap: normal;
                text-overflow: ellipsis;
            }

            .icon {
                cursor: pointer;
            }
        }

        .add-content-button-container {
            padding: 10px 18px;
            border-bottom: 1px solid $modest-color;

            button {
                height: auto;
                padding: 10px;
            }
        }

        .playlistItemsContainer {
            flex: 1 1 auto;
            overflow-y: auto;
            max-height: 70vh;
        }
    }

    .controlPanelBottom {
        display: flex;
        flex-direction: column;
        margin: 24px;

        >*:first-child {
            margin-bottom: 5px;
        }
    }
}