@import "src/styles/variables";

.createScenarioContainer {
    position: relative;
    display: flex;
    height: calc(100vh - $navbar-height);

    .controlPanel {
        position: relative;
        width: 20%;
        background-color: $base-color;
        border: 1px solid $modest-color;
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06),
            0 2px 6px rgba(0, 0, 0, 0.04),
            0 0 1px rgba(0, 0, 0, 0.04);
    }

    .react-flow__attribution {
        display: none;
    }

    .react-flow__node-default.selectable.selected {
        box-shadow: 0 0 3px 0.5px #1f192b;
    }

    .chart-container {
        height: 100%;
        width: 60%;
    }
}