@import "/src/styles/variables.scss";

.root {
    padding: 18px;
    border-radius: $border-radius;
    background-color: white;

    .searchButton {
        margin-left: "10px";
        padding: "2px";
    }
}

.control_panel_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .panel_left > *{
        margin: 0 8px;

        &:first-child {
            margin-inline-start: 0;
        }
    }

    .panel_left, .panel_right{
        display: flex;
        flex-direction: row;
    }

    .dropdown_block {
        display: flex;
    }

    .dropdown {
        margin-left: 12px;
        width: 250px;
    }

    .label {
        margin: 15px 0;
    }

}

