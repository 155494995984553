@import "/src/styles/variables";

.textarea_container {
    position: relative;

    textarea {
        resize: none;
        width: 100%;
        padding: 10px;
        border: none;
        background-color: $modest-color;
        border-radius: 7px;
        font-size: 16px;

        &::placeholder {
            font-size: 14px;
        }

        &:active, &:focus{
            outline: none;
            border: 1px rgba($primary-color, .6) solid;
            box-shadow: 0 0 0 1px rgba($primary-color, .4);
        }
    }

    .max_lenght_label{
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: inline-block;
        color: $font-color-modest;
    }
}