@import "/src/styles/variables";


.input_container {
    position: relative;

    .input_icon_start {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        top: 10px;
        left: 10px;
        background-size: cover;

        [dir="rtl"] & {
            right: auto;
            left: 10px;
        }
    }

    .input_icon_end {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        top: 10px;
        right: 10px;
        background-size: cover;

        [dir="rtl"] & {
            right: auto;
            left: 10px;
        }
    }

    .disabled {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($modest-color, 0.6);
    }

    input {
        width: 100%;
        padding: 10px;
        height: 45px;
        border: 1px solid $modest-color;
        background-color: $modest-color;
        border-radius: 7px;
        font-size: 16px;
        text-align: start;

        &::placeholder {
            font-size: 14px;
        }

        &:active,
        &:focus {
            outline: none;
            border-color: rgba($primary-color, .6);
            box-shadow: 0 0 0 1px rgba($primary-color, .4);
        }
    }

    &.readonly {
        input {
            color: $font-color-modest;
        }
    }

    &.icon_start>input {
        padding-inline-start: 40px;
    }

    &.icon_end>input {
        padding-inline-end: 40px;
    }
}