@import "src/styles/variables";

.cv-item-container {
    .short-name-container {
        .name {
            .name-item {
                span {
                    color: $secondary-color;
                }
            }
        }
    }
}

.conditionsModal-container {
    width: 40vw;

    @media all and (max-width: 1500px) {
        width: 50vw;
    }

    .conditionsCreate-container {
        display: grid;
        grid-template-columns: 1fr 3fr;

        .conditionsList-container {
            border-inline-end: 2px $modest-color solid;

            details {
                margin-top: 16px;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    ul {
                        border: none;
                    }
                }

                summary {
                    font-size: 14px;
                    cursor: pointer;
                    color: $font-color-modest;
                }

                ul {
                    padding-top: 8px;
                    padding-bottom: 16px;
                    padding-inline-start: 8px;
                    margin-inline-end: 16px;
                    border-bottom: 2px $modest-color solid;

                    li {
                        font-size: 14px;
                        cursor: pointer;

                        &.active {
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        .conditionView-container {
            padding: 0 20px;

            .condition-item {
                display: inline-block;
                margin: 4px;
                color: $font-color-modest;
                background-color: black;
                border-radius: $border-radius;
                padding: 7px 12px;
                transition: all 0.5s ease;

                span {
                    color: white;
                }
            }
        }
    }

    .buttons-container {
        border-top: 2px $modest-color solid;
        display: flex;
        justify-content: flex-end;

        >* {
            margin: 10px;
        }
    }
}