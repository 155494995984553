@import "/src/styles/variables.scss";

.intarfaces_list_table {
    border-radius: $border-radius;

    .row {
        position: relative;
        display: grid;
        grid-template-columns: 60px 1fr 3fr 1fr;
        border-radius: $border-radius;

        &::after {
            position: absolute;
            content: "";
            left: 10px;
            right: 10px;
            top: 0;
            height: 1px;
            background-color: $modest-color;
        }

        &:first-child::after {
            display: none;
        }
    }

    .col {
        position: relative;
        padding: 0 30px;
        line-height: 40px;

        &.checkbox {
            padding: 0;
            display: flex;
            justify-content: center;
        }

        &::after {
            position: absolute;
            content: "";
            top: 10px;
            bottom: 10px;
            left: 0;
            width: 1px;
            background-color: $modest-color;
        }

        &:first-child::after {
            display: none;
        }
    }

    .table_head {
        background: #F5F5F5;

        .col {
            font-size: 12px;
            color: $font-color-modest;

            &.settings {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .table_body {
        border: 1px solid #EBEBEB;
        border-top: none;
        border-radius: $border-radius;

        .col {
            font-size: 14px;
            color: black;

            .connected_wifi_name {
                position: relative;
                display: inline-block;
                margin-inline-start: 8px;
                padding-inline-start: 8px;
                font-size: 12px;
                color: $font-color-modest;

                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 12px;
                    bottom: 12px;
                    width: 2px;
                    background-color: $modest-color;
                    z-index: 2;
                }
            }

            &.checkbox {
                display: flex;
                justify-content: center;
                align-items: center;

                .connection_status {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-color: $danger-color;
                    border-radius: 50%;
                    opacity: 0.8;

                    &.active {
                        background-color: $success-color;
                    }
                }
            }

            &.settings {
                display: flex;
                align-items: center;
                justify-content: center;

                >* {
                    margin: 0 4px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.wifi_connect {
    >*{
        margin-top: 10px;
    }
}