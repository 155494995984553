@import "/src/styles/variables.scss";

.server_connect_status {
    padding: 4px;
    border-radius: 50%;
    background-color: rgba($danger-color, 1);
    margin-inline-end: 8px;

    &.active {
        background-color: rgba($success-color, 0.8);
    }
}

.current_playlist_container {
    a {
        color: black;
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $primary-color;
        }

        span {
            display: inline-block;
            margin-inline-end: 4px;
        }
    }
}