@import "src/styles/variables";


.eye-item-container {
    .short-name-container {
        .name {
            line-height: 25px;
        }
    }
}

.eye-input-modal-container {
    width: 30vw;

    .title {
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 18px;
        color: $font-color-modest;
    }

    .eye-input-container {
        position: relative;

        .millisec {
            position: absolute;
            font-size: 14px;
            right: 30px;
            bottom: calc(50% - 7px);
            color: $font-color-modest;

            [dir="rtl"] & {
                right: auto;
                left: 30px;
            }
        }
    }

    .buttons-container {
        margin-top: 25px;
        padding-top: 10px;
        border-top: 1px $modest-color solid;
        display: flex;
        justify-content: flex-end;

        >* {
            margin: 10px;
        }
    }
}