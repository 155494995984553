.enter {
    transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
}

.enterActive {
    transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
}

.exit {
    transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
}

.exitActive {
    transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
}