@import "src/styles/variables";

.loadedItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 5px 20px;
    border-radius: $border-radius;

    &:hover {
        background: #F6F6F6;
    }

    >* {
        padding: 10px;
        flex: 1;
    }

    .loadFileIcon,
    .loadFileCheck {
        flex: 0;
        padding: 0;
    }

    .loadFileCheck {
        cursor: pointer;
    }

    .loadItemName {
        display: flex;
        align-items: center;
        
        .item-name {
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;

            display: inline-block;
            padding-inline-end: 18px;
            border-right: 1px #EBEBEB solid;
        }

        .item-size{
            display: inline-block;
            margin-left: 18px;
            color: $font-color-modest;
        }
    }
}