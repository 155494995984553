@import "/src/styles/variables.scss";

.node_container {
    border: 1px solid gray;
    padding: 5px;
    width: auto;
    z-index: 10;
    background-color: white;
    cursor: grab;

    * {
        cursor: grab;
    }

    &.active {
        box-shadow: 0 0 3px 0.5px #1f192b;
    }
}

.handle {
    z-index: 15 !important;
}

.video_node_container {
    border-radius: 64px;
}

.image_node_container {
    border-radius: 4px;
}

.start_node_container,
.end_node_container {
    position: relative;
    border-radius: 100%;
    width: 35px;
    height: 35px;

    .ball {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
    }
}

.start_node_container {
    padding: 3px;
}

.end_node_container {
    padding: 3px;
}