.sort_panel_item {
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
        font-size: 16px;
        margin-inline-end: 8px;
    }
}

.sort_panel_checkbox {
    padding-inline-end: 18px;
}