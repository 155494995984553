@import "src/styles/variables";

.createScenarioContainer > .leftControlPanel{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .controlPanelTop{
        flex: 1 1 auto;


        .name-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid $modest-color;

            .name {
                font-size: 16px;
                overflow: hidden;
                white-space: nowrap;
                word-wrap: normal;
                text-overflow: ellipsis;
            }

            .icon {
                cursor: pointer;
            }
        }
        
        .inputScenarioData{
            span{
                display: inline-block;
                font-size: 16px;
                padding: 10px 0;
                
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    .controlPanelBot{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 24px;

        >*{
            width: 100%;

            &:first-child{
                margin-bottom: 5px;
            }
        }
    }
}