@import "/src/styles/variables.scss";

.page_container {
    margin: 24px;
    background: white;
    min-height: calc(100vh - $navbar-height - 48px);
    max-height: calc(100vh - $navbar-height - 48px);
    width: calc(100% - 48px);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;

    @media all and (min-width: 1980px) {
        max-width: 100%;
        width: 100%;
        margin: 0;
        margin-top: 24px;
    }
}