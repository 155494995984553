@import "src/styles/variables";

.timetable-item-container {
    .short-name-container {
        .name {
            display: flex;
            flex-direction: column;
            position: relative;
        }
    }
}

.timetable-item-modal-container {
    .timetable-create-container {
        * {
            font-size: 16px;
        }

        >* {
            margin-bottom: 20px;

            >span {
                display: inline-block;
                font-size: 18px;
                margin-bottom: 10px;
            }
        }

        .datetimePicker-container {

            .dates,
            .datetime {
                display: grid;
                margin-bottom: 10px;

                >div {
                    margin-inline-end: 10px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .dates {
                grid-template-columns: repeat(2, 1fr);
            }

            .datetime {
                grid-template-columns: repeat(3, 1fr);
            }

            .allDay-container {
                label {
                    display: inline-block;
                    margin-inline-start: 5px;
                }
            }
        }

        .interval-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;

            >span {
                margin: 0;
            }

            >div {
                margin-inline-end: 10px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .weekPicker-container {
            .weekPicker-items {
                display: flex;
                justify-content: flex-start;

                >span {
                    cursor: pointer;
                    display: inline-block;
                    padding: 13px;
                    margin-inline-end: 10px;
                    font-size: 14px;
                    background-color: $modest-color;
                    color: $font-color-modest;
                    border-radius: 50px;

                    &.active {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }
        }

        .ends-container {
            .end-item {
                display: grid;
                grid-template-columns: 1fr 3fr;
                align-items: center;
                margin-bottom: 10px;

                .radio-container {

                    input,
                    label {
                        cursor: pointer;
                    }

                    label {
                        display: inline-block;
                        margin-inline-start: 5px;
                        line-height: 30px;
                    }
                }

                .end-input {
                    display: flex;
                    align-items: center;

                    >* {
                        margin-inline-end: 10px;
                        width: 100%;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .buttons-container {
        margin-top: 25px;
        padding-top: 10px;
        border-top: 1px $modest-color solid;
        display: flex;
        justify-content: flex-end;

        >* {
            margin: 10px;
        }
    }
}