@import "/src/styles/variables.scss";

.root {
    .setup_list {
        padding: 8px 4px;

        .setup_item {
            position: relative;
            margin-bottom: 8px;
            padding: 8px 16px;
            cursor: pointer;
            border-radius: $border-radius;
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                font-size: 16px;
                margin-inline-start: 8px;
                line-height: 18px;
                font-family: 'Roboto', sans-serif;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 0;
                background-color: $primary-color;
                transition: all 0.3s ease-in-out;
            }

            &.active {
                background: #F5F5F5;
                color: $primary-color;

                &::after {
                    // width: 100%;
                }
            }
        }
    }
}