@import "/src/styles/variables.scss";

.react-flow__handle {
    width: 15px;
    height: 15px;
    background-color: $secondary-color;
    z-index: -1;

    &.react-flow__handle-top {
        top: -10px;
    }

    &.react-flow__handle-bottom {
        bottom: -10px;
    }
}

.reactflow-wrapper {
    position: relative;

    .chart-settings-container {
        position: absolute;
        top: 10px;
        left: 5px;
        display: flex;

        .icon-container {
            cursor: pointer;
            margin: 0 4px;
            padding: 8px;
            background-color: white;
            border-radius: $border-radius;
            z-index: 40;
            height: 41px;
        }

    }
}