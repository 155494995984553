@import "/src/styles/style.scss";

.root {
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.switcher {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.switcher_item {
    padding: 11px 24px;
    background-color: white;
    color: black;
    border-radius: $border-radius;
    margin: 8px;
    cursor: pointer;

    &:first-child {
        margin-inline-start: 0;
    }

    &.active {
        color: white;
        background-color: $primary-color;
    }
}