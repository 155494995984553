@import "src/styles/variables";

.navbar {
    padding: 0 24px;
    background: $base-color;
    height: $navbar-height;

    display: flex;

    .navbar_logo {
        margin-inline-end: 16px;
        display: flex;
        align-items: center;
    }

    .nav_menu {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav_list {
            display: flex;
            flex-direction: row;

            .nav_item {
                position: relative;
                margin: 0 13px;
                height: $navbar-height;
                display: flex;
                align-items: center;

                .nav_link {
                    cursor: pointer;
                    position: relative;
                    display: inline-block;
                    line-height: 16px;
                    color: $font-color-modest;

                    display: flex;
                    align-items: center;
                    height: 100%;

                    &.active,
                    &:hover {
                        color: $primary-color;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        display: block;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 2px;
                        background-color: $primary-color;
                        transition: all 0.3s ease-out;
                        z-index: 10;
                    }

                    &.active::after,
                    &:hover::after {
                        width: 100%;
                    }
                }

                .lang {
                    cursor: pointer;
                    position: relative;
                    color: $font-color-modest;

                    &.active,
                    &:hover {
                        color: $primary-color;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        display: block;
                        top: -8px;
                        bottom: -8px;
                        left: -13px;
                        width: 2px;
                        background-color: $modest-color;
                        transition: all 0.3s ease-out;
                        z-index: 10;

                        [dir="rtl"] & {
                            right: -13px;
                            left: auto;
                        }
                    }
                }

                .dropdown_container {
                    position: absolute;
                    top: 100%;
                    left: -8px;
                    background-color: #FAFAFA;
                    z-index: 100;

                    .dropdown_list {
                        border-radius: $border-radius;

                        li {
                            padding: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .user_container {
            position: relative;
            height: $navbar-height;
            display: flex;
            align-items: center;

            .user_name {
                cursor: pointer;
                display: flex;
                align-items: center;

                span {
                    display: inline-block;
                    margin: 0 4px;
                }

                .arrow_container {
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.3s ease-in-out;

                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }

            .user_menu {
                position: absolute;
                z-index: 40;
                top: 100%;
                right: -14px;
                width: 180px;
                background-color: white;
                border-radius: $border-radius;
                margin-top: 4px;

                .user_menu_item {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 7px 14px;

                    &:hover {
                        background-color: $modest-color;
                    }

                    .item_text {
                        display: block;
                        margin-inline-start: 4px;
                    }
                }

                >* {
                    &:first-child {
                        .user_menu_item {
                            padding-top: 14px;
                        }
                    }

                    &:last-child {
                        .user_menu_item {
                            padding-bottom: 14px;
                        }
                    }
                }
            }
        }
    }
}