@import "/src/styles/variables.scss";

.root {
    padding: 18px;
    border-radius: $border-radius;
    background-color: white;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $font-color-modest;
        margin-bottom: 18px;

        .chart_controls {
            display: flex;
            align-items: center;

            .chart_switcher {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1px;
                border-radius: 24px;

                .switcher_item {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 8px;
                    border: 1px solid #EBEBEB;

                    &:first-child {
                        border-radius: 24px 0 0 24px;
                    }

                    &:last-child {
                        border-radius: 0 24px 24px 0;
                    }

                    &.active {
                        border: none;
                        background-color: $primary-color;
                        color: $base-color;
                    }
                }
            }

            .csv {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .chart_container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}