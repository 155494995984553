@import "src/styles/variables";


.notification-container {
    position: fixed;
    z-index: 999999;
    display: flex;

    &.top-center {
        top: 12px;
        flex-direction: column-reverse;
    }

    &.top-right {
        top: 12px;
        right: 12px;
        flex-direction: column-reverse;
    }

    &.top-left {
        top: 12px;
        left: 12px;
        flex-direction: column-reverse;
    }

    &.bottom-left {
        bottom: 12px;
        left: 12px;
        flex-direction: column;
    }

    &.bottom-right {
        bottom: 12px;
        right: 12px;
        flex-direction: column;
    }

    .notification {
        position: relative;
        background: #fff;
        transition: .3s ease;
        padding: 10px;
        margin-bottom: 15px;
        border-radius: $border-radius;
        box-shadow: 0 0 10px #999;

        display: flex;
        align-items: center;

        min-width: 400px;

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background-color: #0061AE;
            transition: all 0.3s ease-out;
            z-index: 10;
        }

        &.top-center {
            animation: toast-in-top .7s;
            transition: transform .6s ease-in-out;
        }

        &.top-right {
            transition: transform .6s ease-in-out;
            animation: toast-in-right .7s;
        }

        &.bottom-right {
            transition: transform .6s ease-in-out;
            animation: toast-in-right .7s;
        }

        &.top-left {
            transition: transform .6s ease-in;
            animation: toast-in-left .7s;
        }

        &.bottom-left {
            transition: transform .6s ease-in;
            animation: toast-in-left .7s;
        }

        &.success {
            color: $success-color;

            &::before {
                background-color: $success-color;
            }
        }

        &.danger {
            color: $danger-color;

            &::before {
                background-color: $danger-color;
            }
        }

        &:hover {
            opacity: 1;
            box-shadow: 0 0 10px #777;
            cursor: pointer;
        }

        .notification-title {
            font-weight: 700;
            font-size: 16px;
            max-width: 300px;
        }

        .notification-message {
            font-size: 14px;
            font-weight: 400;
            max-width: 500px;
        }

        .notification-image {
            display: inline-flex;
            margin-right: 10px;
        }
    }
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);

    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);

    }

    to {
        transform: translateX(0);
    }
}