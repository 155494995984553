@import "/src/styles/variables.scss";

.custom_label {
    cursor: pointer;
    display: flex;
    justify-content: center;

    &.active {
        z-index: 40;

        .custom_label_conteiner {
            box-shadow: 0 0 3px 0.5px #1f192b;
        }
    }

    .custom_label_conteiner {
        background-color: $primary-color;
        border-radius: 16px;
        color: $base-color;
        padding: 4px 8px;

        .custom_label_text {
            display: block;
            min-width: 60px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
            font-size: 12px;
        }
    }
}