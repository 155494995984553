.root {
    display: flex;

    >* {
        margin-inline-end: 10px;

        button {
            height: auto;
            padding: 8px 16px !important;
        }
    }
}